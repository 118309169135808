<template>
  <div>
    <b-card class="d-fe">
      <div class="w-100">
        <current-membership :status_plan_dad="status_plan" :loaded_service="status_loaded" class="mb-2"></current-membership>

        <div class="mb-2" v-if="!!status_plan.payment_method"> 
          <div class="header-current">{{ $t('memberships.paymentMethods') }}</div>
          <div class="main-container-current-membership">
            <div class="w-100">
              <p class="avenir-medium d-flex align-items-center">
                {{ $t('memberships.chargeProgram') }} 
                <span v-if="status_plan.next_charge" class="d-block ml-05">
                  {{ getDatev2(new Date(status_plan.next_charge)) }}
                </span>
                <b-skeleton class="col-1 ml-05" v-else></b-skeleton>
                <b-img></b-img>
              </p>
              <div class="box-payment-methods mb-1">
                <div class="w-100"> 
                  <div class="d-flex align-items-center" v-if="status_plan.payment_method">
                    <b-img v-if="status_plan.payment_method.card_brand !== 'unknown'" :src="require(`@/assets/images/card-brands/${status_plan.payment_method.card_brand.toLowerCase()}.svg`)" class="card-brand-img"></b-img>
                    <div class="ml-1 text-left">
                      <p class="m-0 avenir-medium"> <span class="card-brand-name">{{ status_plan.payment_method.card_brand }}</span> **** {{status_plan.payment_method.card_last4}}</p>
                      <p class="m-0 gray-small">{{ $t('memberships.checkingExpires') }} {{status_plan.payment_method.card_exp_month}}/{{status_plan.payment_method.card_exp_year.toString().slice(-2)}}</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center w-100" v-else>
                    <b-skeleton type="avatar" class="card-brand-img"></b-skeleton>
                    <div class="ml-1 text-left w-100">
                      <b-skeleton class="col-2"></b-skeleton>
                      <b-skeleton class="col-1"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2">
          <div class="header-current">{{ $t('memberships.paymentHistory') }}</div>
          <div class="main-container-current-membership p-0">
            <b-table
              :items="formatTable(transactions)"
              id="table-billing-setting"
              :fields="$t(`memberships.table_billing`)"
              head-variant="light"        
              responsive
              :busy="!loaded"
              show-empty
            >

              <template #cell(status)="data">
                <b-badge pill :variant="getVariantStatus(data.item.status)" :class="getVariantStatus(data.item.status)">
                  {{$t(`creator.status_transaction.${data.item.status}`)}}
                </b-badge>
              </template>
              <template #cell(invoice)="data">
                <div class="d-flex">
                  <b-button variant="light" v-if="data.item.metadata.invoice_pdf" @click="downloadFile(data.item.metadata.invoice_pdf)">PDF</b-button>
                  <b-button variant="light" class="ml-1" v-if="data.item.metadata.hosted_invoice_url" @click="openInvoiceUrl(data.item.metadata.hosted_invoice_url)">{{ $t('profile.quickView') }}</b-button>
                  <span v-else> --- </span>
                </div>
              </template>

              <template #empty>
                <no-Results
                  v-if="transactions.length === 0"
                  :title="$t('profile.noData')"
                  :show_button= "false"
                  :border="'border-0-custom-activity-table'"
                />
              </template>

              <template #table-busy>
                <div class="text-center my-2 h-min-empty d-flex align-items-center justify-content-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
            <b-pagination v-model="current_page" :total-rows="total_rows" :per-page="per_page" v-if="loaded && transactions.length !== 0" class="d-flex justify-content-center w-100"></b-pagination>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BImg,
  BTable,
  BButton,
  BSkeleton,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import service from '@/services/proposals';
import others from '@/services/others';
import { downloadFile } from '@/libs/utils/others';
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: 'AccountSettingBilling',
  components: {
    BCard,
    BBadge,
    BImg,
    BTable,
    BButton,
    BSkeleton,
    BPagination,
    BSpinner,
    CurrentMembership: () => import('./CurrentMembership.vue'),
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
  },

  data() {
    return {
      downloadFile,
      getDatev2,
      status_plan: {},
      current_page: 1,
      per_page: 10,
      transactions: [],
      total_rows: 0,
      loaded: false,
      status_loaded: false,
    };
  },
  created() {
    this.getStatusPlan();
    this.getTransactions();
  },
  methods: {
    openInvoiceUrl(url) {
      window.open(url, '_blank')
    },
    getTransactions() {
      this.loaded = false;
      others.transactionsWithFilters({}, this.per_page, this.current_page).then((response) => {
        this.transactions = response.results;
        this.total_rows = response.count;
        setTimeout(() => {
          this.loaded = true;
        }, 500);
      })
    },
    formatTable(array_data) {
      const items = [];
      array_data.forEach((item) => {
        items.push({
          id: item.order_id,
          status: item.status,
          amount: `$ ${item.amount}`,
          updated_at: item.paid_at,
          metadata: item.metadata ?? {}
        })
      });
      return items
    },
    getStatusPlan() {
      service.statusPlan().then((response) => {
        this.status_plan = response;
        this.status_loaded = true;
      });
    },
    getVariantStatus(status) {
      if (status === 'paid') return 'light-success'
      if (status === 'unpaid' || status === 'rejected') return 'light-danger'
      if (status === 'program_withdrawal') return 'light-warning'
      if (status === 'withdrawal') return 'light-info'
      return 'light-secondary'
    },
  },
  watch: {
    current_page() {
      this.getTransactions()
    }
  }
}
</script>
<style scoped lang="scss">
.ml-05 {
  margin: 0 0 0 0.5em;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.main-container-current-membership {
  width: 100%;
  border: 1px solid #cccccc83;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  flex-wrap: wrap;

  .box-payment-methods {
    border: 1px solid #cccccc83;
    border-radius: 0.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.8em;
    .card-brand-img {
      width: 30px !important;
      height: 30px !important;
    }
    .card-brand-name {
      text-transform: capitalize;
    }
    .name-user-card {
      text-transform: uppercase;
    }
    .gray-small {
      color: #6c757d;
      font-size: 11px;
      font-family: 'avenir-medium';
    }
  }
}
.header-current {
  background-color: #fafafa;
  width: 100%;
  border: 1px solid #cccccc83;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  padding: 8px 16px;
  font-size: 16px;
}

</style>